import React from 'react';
import { HorizontalLayout, VerticalLayout, TranslatedLabelWithData } from '@vcc-gcs/bookingengine-shared-frontend';
import { TimeLineSectionHeading } from '../TimeLineSectionHeading';
import { Text } from 'vcc-ui';
import { formattedDate, formattedTime } from '../../../shared/domain/utils/dateUtils';
import { useTranslation } from 'react-i18next';

export const DateTimeCard = ({
  dropoffOptionObj,
  dropoffOptionTranslated,
  subDropoffOptionsObj,
  pickupAddress,
  timeslots,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <HorizontalLayout id={'bookingContent-date-time'} style={{ margin: '0.5em 0em' }} className={'date-time'}>
      <VerticalLayout data-test-id={'dateandTime'} className={'date-time-content'} spacing={10}>
        <TimeLineSectionHeading>{t(`appointments.eventType.appointment.datetime`)}</TimeLineSectionHeading>
        <ul>
          <li>
            <Text>
              {[
                `${formattedDate(timeslots[0].start, i18n.language)}, ${formattedTime(
                  timeslots[0].start,
                  i18n.language,
                )}`,
                timeslots[0].end && formattedTime(timeslots[0].end, i18n.language),
              ]
                .filter(Boolean)
                .join(' - ')}
            </Text>
          </li>
          <li>
            <Text data-test-id={'dropoffOptionText'}>
              {(dropoffOptionObj && dropoffOptionObj.heading) || dropoffOptionTranslated}
            </Text>
            {subDropoffOptionsObj?.length > 0 && (
              <ul>
                {subDropoffOptionsObj.map((subDropoffOption) => (
                  <li key={subDropoffOption.uid}>
                    <Text>{subDropoffOption.heading}</Text>
                  </li>
                ))}
              </ul>
            )}
          </li>
          {pickupAddress && (
            <li>
              <Text data-test-id={'pickupAddress'} as="div">
                <TranslatedLabelWithData
                  textId={`appointments.eventType.appointment.pickupAddress`}
                  data={pickupAddress[0]}
                />
              </Text>
            </li>
          )}
        </ul>
      </VerticalLayout>
    </HorizontalLayout>
  );
};
