import React, { useContext, Suspense } from 'react';
import { CheckUnderMaintenanceWrapper, VerticalLayout } from '@vcc-gcs/bookingengine-shared-frontend';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Context } from './store';
import { ErrorView } from './ErrorView';
import { AppointmentsInbox } from './appointments-inbox/AppointmentsInbox';
import { VersionIdentifier } from './shared/components/version-identifier/VersionIdentifier';
import { LoadingBar } from 'vcc-ui';
import { AuthWrapper } from './authentication/AuthWrapper';

const App = ({ i18next }) => {
  const [state] = useContext(Context);
  const { language } = state;
  const buildId = process.env.REACT_APP_BUILD_ID || null;
  const flags = useFlags();
  const appMaintenance = window.config.appUnderMaintenance || flags['retailer-web-maintenance'];

  i18next.changeLanguage(language);

  return (
    <Suspense fallback={<LoadingBar isLoading />}>
      <CheckUnderMaintenanceWrapper
        i18next={i18next}
        title="appBlockage.maintenance.title"
        body="appBlockage.maintenance.body"
        condition={appMaintenance}
      >
        <AuthWrapper>
          <VerticalLayout style={{ flex: '1 0 auto' }}>
            <Switch>
              <Route path="/vida" exact component={() => <AppointmentsInbox />} />
              <Route exact path="/vida/:id" component={() => <AppointmentsInbox />} />
              <Route exact path="/forbidden" component={() => <ErrorView />} />
              <Route path="/" exact render={() => <Redirect to={'/vida'} />} />
            </Switch>
            {buildId && <VersionIdentifier buildId={buildId} />}
          </VerticalLayout>
        </AuthWrapper>
      </CheckUnderMaintenanceWrapper>
    </Suspense>
  );
};

export default App;
