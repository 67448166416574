import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { StyleProvider, ThemeProvider } from 'vcc-ui';
import volvo from 'vcc-ui/lib/themes/volvo';
import { ApolloProvider } from '@apollo/client';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import './index.css';
import * as serviceWorker from './serviceWorker';
import client from './apolloState';
import i18next from './i18n';
import { Store } from './store';
import App from './App';

const publicUrl = process.env.PUBLIC_URL || '';

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: window.config.launchDarkly.clientSideID,
    context: {
      kind: 'user',
      anonymous: true,
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  });

  ReactDOM.render(
    <LDProvider>
      <BrowserRouter basename={publicUrl}>
        <Store>
          <I18nextProvider i18n={i18next}>
            <ApolloProvider client={client}>
              <StyleProvider>
                <ThemeProvider
                  theme={{
                    ...volvo,
                    fontsPath: `${publicUrl}/vcc-ui/fonts/`,
                    logoImagesPath: `${publicUrl}/vcc-ui/images/`,
                  }}
                >
                  <App i18next={i18next} />
                </ThemeProvider>
              </StyleProvider>
            </ApolloProvider>
          </I18nextProvider>
        </Store>
      </BrowserRouter>
    </LDProvider>,
    document.getElementById('root'),
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
